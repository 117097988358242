import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import {
  Box,
  Stack,
  styled,
  useTheme,
  Container,
  useMediaQuery,
} from '@mui/material';

const VideoBannerWrapper = styled(Box)(({theme}) => ({
  width: '100%',
  height: '100%',
  maxHeight: '680px',
  overflow: 'hidden',
  position: 'relative',
  backgroundPosition: 'center',
  [`& .overlay`]: {
    width: '100%',
    marginTop: '-5%',
    // marginBottom: '-10%',
  },
  [theme.breakpoints.down('md')]: {
    height: 'auto',
  },
}));

const TextWrapper = styled(Box)(({theme}) => ({
  top: '0',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  backgroundColor: '#0C111D54',
}));

const ContentBox = styled(Stack)(({theme, customRibbon}) => ({
  width: '60%',
  padding: '0px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    padding: '36px 8px 42px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: customRibbon ? '0px 8px 48px' : '36px 8px 42px',
  },
}));

const VideoBanner = ({bannerURL, customRibbon, children}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <VideoBannerWrapper>
      <Box className='overlay'>
        <ReactPlayer
          loop
          muted
          playing
          playsinline
          url={bannerURL}
          width={customRibbon && isMobile ? 'auto' : '100%'}
          height={customRibbon && isMobile ? '400px' : '100%'}
        />
      </Box>
      <TextWrapper>
        <Container maxWidth='xl'>
          <ContentBox
            spacing={2}
            alignItems='left'
            justifyContent='left'
            customRibbon={customRibbon}>
            {children}
          </ContentBox>
        </Container>
      </TextWrapper>
      {customRibbon}
    </VideoBannerWrapper>
  );
};

VideoBanner.propTypes = {
  children: PropTypes.node,
  customRibbon: PropTypes.node,
  bannerURL: PropTypes.string.isRequired,
};

export default VideoBanner;
