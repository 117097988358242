import {componentsTheme} from 'mui-core';
import {MyriadProFonts} from 'assets/fonts/MyriadPro';
import {createTheme, responsiveFontSizes} from '@mui/material/styles';

// FiraSans Variants
const Display = {
  lg: {
    thin: {
      fontWeight: 300,
      lineHeight: '125%',
      fontSize: '5.1875rem',
      fontFamily: 'Fira Sans',
    },
    medium: {
      fontWeight: 500,
      lineHeight: '125%',
      fontSize: '5.1875rem',
      fontFamily: 'Fira Sans',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '125%',
      fontSize: '5.1875rem',
      fontFamily: 'Fira Sans',
    },
    black: {
      fontWeight: 900,
      lineHeight: '125%',
      fontSize: '5.1875rem',
      fontFamily: 'Fira Sans',
    },
  },
  md: {
    thin: {
      fontWeight: 300,
      lineHeight: '125%',
      fontSize: '4.3125rem',
      fontFamily: 'Fira Sans',
    },
    medium: {
      fontWeight: 500,
      lineHeight: '125%',
      fontSize: '4.3125rem',
      fontFamily: 'Fira Sans',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '125%',
      fontSize: '4.3125rem',
      fontFamily: 'Fira Sans',
    },
    black: {
      fontWeight: 900,
      lineHeight: '125%',
      fontSize: '4.3125rem',
      fontFamily: 'Fira Sans',
    },
  },
  sm: {
    thin: {
      fontWeight: 300,
      lineHeight: '125%',
      fontSize: '3.5625rem',
      fontFamily: 'Fira Sans',
    },
    medium: {
      fontWeight: 500,
      lineHeight: '125%',
      fontSize: '3.5625rem',
      fontFamily: 'Fira Sans',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '125%',
      fontSize: '3.5625rem',
      fontFamily: 'Fira Sans',
    },
    black: {
      fontWeight: 900,
      lineHeight: '125%',
      fontSize: '3.5625rem',
      fontFamily: 'Fira Sans',
    },
  },
};
const Header = {
  lg: {
    // variant = h1
    thin: {
      fontWeight: 300,
      fontSize: '3rem',
      lineHeight: '125%',
      fontFamily: 'Fira Sans',
    },
    medium: {
      fontWeight: 500,
      fontSize: '3rem',
      lineHeight: '125%',
      fontFamily: 'Fira Sans',
    },
    bold: {
      fontWeight: 700,
      fontSize: '3rem',
      lineHeight: '125%',
      fontFamily: 'Fira Sans',
    },
    black: {
      fontWeight: 900,
      fontSize: '3rem',
      lineHeight: '125%',
      fontFamily: 'Fira Sans',
    },
  },
  md: {
    // variant = h2
    thin: {
      fontWeight: 300,
      lineHeight: '125%',
      fontSize: '2.5rem',
      fontFamily: 'Fira Sans',
    },
    medium: {
      fontWeight: 500,
      lineHeight: '125%',
      fontSize: '2.5rem',
      fontFamily: 'Fira Sans',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '125%',
      fontSize: '2.5rem',
      fontFamily: 'Fira Sans',
    },
    black: {
      fontWeight: 900,
      lineHeight: '125%',
      fontSize: '2.5rem',
      fontFamily: 'Fira Sans',
    },
  },
  sm: {
    // variant = h3
    thin: {
      fontWeight: 300,
      lineHeight: '125%',
      fontSize: '2.0625rem',
      fontFamily: 'Fira Sans',
    },
    medium: {
      fontWeight: 500,
      lineHeight: '125%',
      fontSize: '2.0625rem',
      fontFamily: 'Fira Sans',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '125%',
      fontSize: '2.0625rem',
      fontFamily: 'Fira Sans',
    },
    black: {
      fontWeight: 900,
      lineHeight: '125%',
      fontSize: '2.0625rem',
      fontFamily: 'Fira Sans',
    },
  },
};
const Caption = {
  light: {
    fontWeight: 300,
    lineHeight: '150%',
    fontSize: '2.0625rem',
    fontFamily: 'Fira Sans',
  },
  medium: {
    fontWeight: 500,
    lineHeight: '150%',
    fontSize: '2.0625rem',
    fontFamily: 'Fira Sans',
  },
};

// Inter Variants
const Title = {
  lg: {
    // variant = h4
    thin: {
      fontWeight: 300,
      lineHeight: '125%',
      fontSize: '1.75rem',
      fontFamily: 'Inter',
    },
    medium: {
      fontWeight: 500,
      lineHeight: '125%',
      fontSize: '1.75rem',
      fontFamily: 'Inter',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '125%',
      fontSize: '1.75rem',
      fontFamily: 'Inter',
    },
    black: {
      fontWeight: 900,
      lineHeight: '125%',
      fontSize: '1.75rem',
      fontFamily: 'Inter',
    },
  },
  md: {
    // variant = h5
    thin: {
      fontWeight: 300,
      lineHeight: '125%',
      fontFamily: 'Inter',
      fontSize: '1.4375rem',
    },
    medium: {
      fontWeight: 500,
      lineHeight: '125%',
      fontFamily: 'Inter',
      fontSize: '1.4375rem',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '125%',
      fontFamily: 'Inter',
      fontSize: '1.4375rem',
    },
    black: {
      fontWeight: 900,
      lineHeight: '125%',
      fontFamily: 'Inter',
      fontSize: '1.4375rem',
    },
  },
  sm: {
    // variant = h6
    thin: {
      fontWeight: 300,
      lineHeight: '125%',
      fontFamily: 'Inter',
      fontSize: '1.1875rem',
    },
    medium: {
      fontWeight: 500,
      lineHeight: '125%',
      fontFamily: 'Inter',
      fontSize: '1.1875rem',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '125%',
      fontFamily: 'Inter',
      fontSize: '1.1875rem',
    },
    black: {
      fontWeight: 900,
      lineHeight: '125%',
      fontFamily: 'Inter',
      fontSize: '1.1875rem',
    },
  },
  xs: {
    thin: {
      fontWeight: 300,
      fontSize: '1rem',
      lineHeight: '125%',
      fontFamily: 'Inter',
    },
    medium: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '125%',
      fontFamily: 'Inter',
    },
    bold: {
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '125%',
      fontFamily: 'Inter',
    },
    black: {
      fontWeight: 900,
      fontSize: '1rem',
      lineHeight: '125%',
      fontFamily: 'Inter',
    },
  },
};
const Text = {
  xl: {
    regular: {
      fontWeight: 400,
      lineHeight: '150%',
      fontFamily: 'Inter',
      fontSize: '1.4375rem',
    },
    italic: {
      fontWeight: 400,
      lineHeight: '150%',
      fontStyle: 'italic',
      fontFamily: 'Inter',
      fontSize: '1.4375rem',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '150%',
      fontFamily: 'Inter',
      fontSize: '1.4375rem',
    },
  },
  lg: {
    regular: {
      fontWeight: 400,
      lineHeight: '150%',
      fontFamily: 'Inter',
      fontSize: '1.1875rem',
    },
    italic: {
      fontWeight: 400,
      lineHeight: '150%',
      fontStyle: 'italic',
      fontFamily: 'Inter',
      fontSize: '1.1875rem',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '150%',
      fontFamily: 'Inter',
      fontSize: '1.1875rem',
    },
  },
  md: {
    regular: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '150%',
      fontFamily: 'Inter',
    },
    italic: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '150%',
      fontStyle: 'italic',
      fontFamily: 'Inter',
    },
    bold: {
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '150%',
      fontFamily: 'Inter',
    },
  },
  sm: {
    regular: {
      fontWeight: 400,
      lineHeight: '150%',
      fontFamily: 'Inter',
      fontSize: '0.8125rem',
    },
    italic: {
      fontWeight: 400,
      lineHeight: '150%',
      fontStyle: 'italic',
      fontFamily: 'Inter',
      fontSize: '0.8125rem',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '150%',
      fontFamily: 'Inter',
      fontSize: '0.8125rem',
    },
  },
  xs: {
    regular: {
      fontWeight: 400,
      lineHeight: '150%',
      fontFamily: 'Inter',
      fontSize: '0.6875rem',
    },
    italic: {
      fontWeight: 400,
      lineHeight: '150%',
      fontStyle: 'italic',
      fontFamily: 'Inter',
      fontSize: '0.6875rem',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '150%',
      fontFamily: 'Inter',
      fontSize: '0.6875rem',
    },
  },
};
const Quote = {
  light: {
    fontWeight: 300,
    lineHeight: '150%',
    fontFamily: 'Inter',
    fontStyle: 'italic',
    fontSize: '2.0625rem',
  },
  medium: {
    fontWeight: 500,
    lineHeight: '150%',
    fontFamily: 'Inter',
    fontStyle: 'italic',
    fontSize: '2.0625rem',
  },
};
const Label = {
  lg: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '150%',
    fontFamily: 'Inter',
  },
  md: {
    fontWeight: 500,
    lineHeight: '150%',
    fontFamily: 'Inter',
    fontSize: '0.8125rem',
  },
  sm: {
    fontWeight: 500,
    lineHeight: '150%',
    fontFamily: 'Inter',
    fontSize: '0.6825rem',
  },
  xs: {
    fontWeight: 500,
    lineHeight: '150%',
    fontSize: '0.563rem',
    fontFamily: 'Inter',
  },
};

const getAppTheme = mode => {
  const theme = createTheme({
    palette: {
      mode,
      primary: {
        main: '#4E36F5',
        dark: '#635BFF',
        light: '#635BFF',
        darker: '#4635AC',
      },
      secondary: {
        main: '#086EA1',
        dark: '#2A78C0',
        light: '#6EE2FF',
        constrastDark: '#21609C',
      },
      success: {
        main: '#52c41a',
        light: '#689f38',
        dark: '#507D2C',
        contrastText: 'white', // for button: success status
      },
      warn: {
        dark: '#A36200',
      },
      purplebg4perc: {
        main: '#7960DF0A', // '#faf9fd',
      },
      purplebg8perc: {
        main: '#efebfa', // '#7960DF14',
      },
      purplebg12perc: {
        main: '#7960DF1F',
      },
      purplebg: {
        main: '#f3f2fc',
      },
      darkGray: {
        main: '#333333',
        contrastText: 'white',
      },
      orange: {
        main: '#FFB400',
      },
      lightBlue: {
        main: '#3bc4ff',
      },
      grayLight: {
        main: '#f9f8ff',
      },
      lightGrey: {
        main: '#F9FAFB',
        light: '#F1F1F4',
      },
      midGray: {
        main: '#a0a4b5',
        contrastText: 'white',
      },
      grayBorder: {
        main: '#D8D8D8',
      },
      grey: {
        main: '#667085',
        contrastText: '#fff',
      },
      grayText: {
        main: '#707070',
        contrastText: 'white',
      },
      dialogDarkBG: {
        main: '#121212',
      },
      shadeyBlack: {
        main: '#212636',
        contrastText: 'white',
      },
      // color for icon in dark-mode
      brand: {
        main: '#7A5AFB',
      },
      iconBrandColor: {
        main:
          'brightness(0) saturate(100%) invert(35%) sepia(25%) saturate(6218%) hue-rotate(237deg) brightness(101%) contrast(94%)',
      },
      iconLightColor: {
        main:
          'brightness(0) saturate(100%) invert(100%) sepia(86%) saturate(2%) hue-rotate(283deg) brightness(107%) contrast(101%)',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1400,
        xl: 1536,
      },
    },
    components: {
      ...componentsTheme.components,
      MuiButton: {
        styleOverrides: {
          root: ({ownerState}) => ({
            ...(ownerState.color === 'primary' && {
              color: '#635BFF',
            }),
            ...(ownerState.variant === 'contained' &&
              ownerState.color === 'primary' &&
              ownerState.disabled !== true && {
                color: '#fff',
                border: '1px solid #4E36F5',
                boxShadow: '0px 1px 2px 0px #00000014',
                background: 'linear-gradient(#635BFF, #4E36F5)',
              }),
            ...(ownerState.variant === 'contained' &&
              ownerState.color === 'primary' &&
              ownerState.disabled !== true &&
              (ownerState.endIcon || ownerState.startIcon) && {
                '.MuiButton-endIcon, .MuiButton-startIcon': {
                  filter: theme.palette.iconLightColor.main,
                },
              }),
            ...(ownerState.variant === 'outlined' &&
              ownerState.color === 'primary' &&
              (ownerState.endIcon || ownerState.startIcon) && {
                '.MuiButton-endIcon, .MuiButton-startIcon': {
                  filter: theme.palette.iconBrandColor.main,
                },
                '&:hover': {
                  '.MuiButton-endIcon, .MuiButton-startIcon': {
                    filter: theme.palette.iconLightColor.main,
                  },
                },
              }),

            ...(ownerState.variant === 'outlined' &&
              ownerState.color === 'primary' && {
                color: '#635BFF',
                border: '1px solid #635BFF80',
                boxShadow: '0px 1px 2px 0px #00000014',
              }),
            ...(ownerState.size === 'medium' && {
              ...Label.md,
              minHeight: '40px',
              padding: '4px 12px',
              '.MuiButton-endIcon, .MuiButton-startIcon': {
                width: '18px',
                height: '18px',
              },
            }),
            ...(ownerState.size === 'small' && {
              ...Label.sm,
              '.MuiButton-endIcon, .MuiButton-startIcon': {
                width: '14px',
                height: '14px',
              },
            }),
            ...(ownerState.size === 'large' && {
              ...Label.lg,
              '.MuiButton-endIcon, .MuiButton-startIcon': {
                width: '20px',
                height: '20px',
              },
            }),
            ...(ownerState.width && {
              width: ownerState.width,
            }),
            borderRadius: '8px',
            textTransform: 'capitalize',
          }),
        },
      },
      MuiCssBaseline: {
        styleOverrides:
          //     `
          //     @font-face {
          //       font-family: 'Poppins';
          //       src:  url('${PoppinsFonts.REGULAR.EOT}');
          //       src:  url('${PoppinsFonts.REGULAR.EOT}?#iefix') format('embedded-opentype'),
          //             url('${PoppinsFonts.REGULAR.WOFF2}') format('woff2'),
          //             url('${PoppinsFonts.REGULAR.OTF}') format('otf'),
          //             url('${PoppinsFonts.REGULAR.WOFF}') format('woff'),
          //             url('${PoppinsFonts.REGULAR.TTF}') format('truetype');
          //       font-weight: normal;
          //       font-style: normal;
          //       font-display: swap;
          //     }
          //     @font-face {
          //       font-family: 'Poppins';
          //       src:  url('${PoppinsFonts.LIGHT.EOT}');
          //       src:  url('${PoppinsFonts.LIGHT.EOT}?#iefix') format('embedded-opentype'),
          //             url('${PoppinsFonts.LIGHT.WOFF2}') format('woff2'),
          //             url('${PoppinsFonts.LIGHT.WOFF}') format('woff'),
          //             url('${PoppinsFonts.LIGHT.TTF}') format('truetype');
          //       font-weight: 300;
          //       font-style: normal;
          //       font-display: swap;
          //     }
          //     @font-face {
          //       font-family: 'Poppins';
          //       src:  url('${PoppinsFonts.MEDIUM.EOT}');
          //       src:  url('${PoppinsFonts.MEDIUM.EOT}?#iefix') format('embedded-opentype'),
          //             url('${PoppinsFonts.MEDIUM.WOFF2}') format('woff2'),
          //             url('${PoppinsFonts.MEDIUM.WOFF}') format('woff'),
          //             url('${PoppinsFonts.MEDIUM.TTF}') format('truetype');
          //       font-weight: 500;
          //       font-style: normal;
          //       font-display: swap;
          //     }
          //     @font-face {
          //       font-family: 'Poppins';
          //       src:  url('${PoppinsFonts.BOLD.EOT}');
          //       src:  url('${PoppinsFonts.BOLD.EOT}?#iefix') format('embedded-opentype'),
          //             url('${PoppinsFonts.BOLD.WOFF2}') format('woff2'),
          //             url('${PoppinsFonts.BOLD.WOFF}') format('woff'),
          //             url('${PoppinsFonts.BOLD.TTF}') format('truetype');
          //       font-weight: 600;
          //       font-style: normal;
          //       font-display: swap;
          //     }
          //     /* Scrollbar styles */
          //     &::-webkit-scrollbar {
          //       width: 4px !important;
          //       height: 4px !important;
          //       background-color: rgba(123, 96, 223, 0.3);
          //     }
          //     &::-webkit-scrollbar-thumb {
          //       background-color: #7960DF !important;
          //       border-radius: 14px;
          //     }
          // `,

          ` @font-face {
      font-family: 'MyriadPro';
      src: url('${MyriadProFonts.REGULAR.OTF}');
      src: url('${MyriadProFonts.REGULAR.OTF}') format('otf');
      src: url('${MyriadProFonts.REGULAR.TTF}') format('ttf');
      src: url('${MyriadProFonts.REGULAR.TTF}') format('eot');
      src: url('${MyriadProFonts.REGULAR.TTF}') format('woff');
      src: url('${MyriadProFonts.REGULAR.TTF}') format('woff2');
      font-weight: 400;
      font-style: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'MyriadPro';
      src: url('${MyriadProFonts.LIGHT.OTF}');
      src: url('${MyriadProFonts.LIGHT.OTF}') format('otf');
      src: url('${MyriadProFonts.LIGHT.TTF}') format('ttf');
      src: url('${MyriadProFonts.LIGHT.TTF}') format('eot');
      src: url('${MyriadProFonts.LIGHT.TTF}') format('woff');
      src: url('${MyriadProFonts.LIGHT.TTF}') format('woff2');
      font-weight: 300;
      font-display: swap;
    }
    @font-face {
      font-family: 'MyriadPro';
      src: url('${MyriadProFonts.SEMIBOLD.OTF}');
      src: url('${MyriadProFonts.SEMIBOLD.OTF}') format('otf');
      src: url('${MyriadProFonts.SEMIBOLD.TTF}') format('ttf');
      src: url('${MyriadProFonts.SEMIBOLD.TTF}') format('eot');
      src: url('${MyriadProFonts.SEMIBOLD.TTF}') format('woff');
      src: url('${MyriadProFonts.SEMIBOLD.TTF}') format('woff2');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'MyriadPro';
      src: url('${MyriadProFonts.BOLD.OTF}');
      src: url('${MyriadProFonts.BOLD.OTF}') format('otf');
      src: url('${MyriadProFonts.BOLD.TTF}') format('ttf');
      src: url('${MyriadProFonts.BOLD.TTF}') format('eot');
      src: url('${MyriadProFonts.BOLD.TTF}') format('woff');
      src: url('${MyriadProFonts.BOLD.TTF}') format('woff2');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
    }
    /* Scrollbar styles */
    &::-webkit-scrollbar {
      width: 4px !important;
      height: 4px !important;
      background-color: rgba(123, 96, 223, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #0367FF !important;
      border-radius: 14px;
    }`,
      },
    },
    typography: {
      fontFamily: 'MyriadPro',
      h1: {
        fontSize: '50px',
        fontWeight: 500,
      },
      h2: {
        fontSize: '45px',
        fontWeight: 500,
      },
      h3: {
        fontSize: '30px',
        fontWeight: 500,
      },
      h4: {
        fontSize: '25px',
        fontWeight: 500,
      },
      h5: {
        fontSize: '22px',
        fontWeight: 500,
      },
      h6: {
        fontSize: '16px',
        fontWeight: 500,
      },
      overline: {
        fontSize: '12px',
        fontWeight: 400,
      },
      caption: {
        fontSize: '12px',
        fontWeight: 400,
      },
      button: {
        fontWeight: 400,
      },
    },
  });
  // Design System Typography Variants
  // Display
  theme.typography['display-lg-thin'] = {...Display.lg.thin};
  theme.typography['display-md-thin'] = {...Display.md.thin};
  theme.typography['display-sm-thin'] = {...Display.sm.thin};
  theme.typography['display-lg-bold'] = {...Display.lg.bold};
  theme.typography['display-md-bold'] = {...Display.md.bold};
  theme.typography['display-sm-bold'] = {...Display.sm.bold};
  theme.typography['display-lg-black'] = {...Display.lg.black};
  theme.typography['display-md-black'] = {...Display.md.black};
  theme.typography['display-sm-black'] = {...Display.sm.black};
  theme.typography['display-lg-medium'] = {...Display.lg.medium};
  theme.typography['display-md-medium'] = {...Display.md.medium};
  theme.typography['display-sm-medium'] = {...Display.sm.medium};
  // Header
  theme.typography['header-lg-thin'] = {...Header.lg.thin};
  theme.typography['header-md-thin'] = {...Header.md.thin};
  theme.typography['header-sm-thin'] = {...Header.sm.thin};
  theme.typography['header-lg-bold'] = {...Header.lg.bold};
  theme.typography['header-md-bold'] = {...Header.md.bold};
  theme.typography['header-sm-bold'] = {...Header.sm.bold};
  theme.typography['header-lg-black'] = {...Header.lg.black};
  theme.typography['header-md-black'] = {...Header.md.black};
  theme.typography['header-sm-black'] = {...Header.sm.black};
  theme.typography['header-lg-medium'] = {...Header.lg.medium};
  theme.typography['header-md-medium'] = {...Header.md.medium};
  theme.typography['header-sm-medium'] = {...Header.sm.medium};
  // Title
  theme.typography['title-lg-thin'] = {...Title.lg.thin};
  theme.typography['title-md-thin'] = {...Title.md.thin};
  theme.typography['title-sm-thin'] = {...Title.sm.thin};
  theme.typography['title-xs-thin'] = {...Title.xs.thin};
  theme.typography['title-lg-bold'] = {...Title.lg.bold};
  theme.typography['title-md-bold'] = {...Title.md.bold};
  theme.typography['title-sm-bold'] = {...Title.sm.bold};
  theme.typography['title-xs-bold'] = {...Title.xs.bold};
  theme.typography['title-lg-black'] = {...Title.lg.black};
  theme.typography['title-md-black'] = {...Title.md.black};
  theme.typography['title-sm-black'] = {...Title.sm.black};
  theme.typography['title-xs-black'] = {...Title.xs.black};
  theme.typography['title-lg-medium'] = {...Title.lg.medium};
  theme.typography['title-md-medium'] = {...Title.md.medium};
  theme.typography['title-sm-medium'] = {...Title.sm.medium};
  theme.typography['title-xs-medium'] = {...Title.xs.medium};
  // Text
  theme.typography['text-xl-regular'] = {...Text.xl.regular};
  theme.typography['text-lg-regular'] = {...Text.lg.regular};
  theme.typography['text-md-regular'] = {...Text.md.regular};
  theme.typography['text-sm-regular'] = {...Text.sm.regular};
  theme.typography['text-xs-regular'] = {...Text.xs.regular};
  theme.typography['text-xl-italic'] = {...Text.xl.italic};
  theme.typography['text-lg-italic'] = {...Text.lg.italic};
  theme.typography['text-md-italic'] = {...Text.md.italic};
  theme.typography['text-sm-italic'] = {...Text.sm.italic};
  theme.typography['text-xs-italic'] = {...Text.xs.italic};
  theme.typography['text-xl-bold'] = {...Text.xl.bold};
  theme.typography['text-lg-bold'] = {...Text.lg.bold};
  theme.typography['text-md-bold'] = {...Text.md.bold};
  theme.typography['text-sm-bold'] = {...Text.sm.bold};
  theme.typography['text-xs-bold'] = {...Text.xs.bold};
  // Label
  theme.typography['label-lg'] = {...Label.lg};
  theme.typography['label-md'] = {...Label.md};
  theme.typography['label-sm'] = {...Label.sm};
  theme.typography['label-xs'] = {...Label.xs};
  // Quote
  theme.typography['quote-light'] = {...Quote.light};
  theme.typography['quote-medium'] = {...Quote.medium};
  // Caption
  theme.typography['caption-light'] = {...Caption.light};
  theme.typography['caption-medium'] = {...Caption.medium};

  // custom Typography Components
  const defaultFontProps = {
    fontFamily: 'MyriadPro',
  };
  theme.typography['h7'] = {
    ...defaultFontProps,
    fontSize: 14,
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  };
  theme.typography['body1'] = {
    ...defaultFontProps,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  };
  theme.typography['body2'] = {
    ...defaultFontProps,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
    },
  };
  theme.typography['bodyL'] = {
    ...defaultFontProps,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  };
  theme.typography['bodyXL'] = {
    ...defaultFontProps,
    fontSize: 18,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  };
  theme.typography['subtitle1'] = {
    ...defaultFontProps,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  };
  theme.typography['subtitle2'] = {
    ...defaultFontProps,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  };
  theme.typography['bannerTitle'] = {
    ...defaultFontProps,
    fontSize: 50,
    fontWeight: 500,
    lineHeight: '50px',
    [theme.breakpoints.down('md')]: {
      fontSize: 42,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
      lineHeight: '40px',
    },
  };
  theme.typography['metricNo'] = {
    ...defaultFontProps,
    fontSize: 28,
    fontWeight: 300,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  };
  theme.typography['title55'] = {
    ...defaultFontProps,
    // fontFamily: 'Poppins',
    lineHeight: '70px',
    fontWeight: 500,
    fontSize: 55,
    [theme.breakpoints.down('md')]: {
      lineHeight: '30px',
      fontSize: 28,
    },
  };
  theme.typography['title32'] = {
    ...defaultFontProps,
    // fontFamily: 'Poppins',
    lineHeight: '45px',
    fontWeight: 400,
    fontSize: 32,
    [theme.breakpoints.down('md')]: {
      lineHeight: '30px',
      fontSize: 28,
    },
  };
  theme.typography['desc25'] = {
    ...defaultFontProps,
    // fontFamily: 'Poppins',
    lineHeight: '35px',
    fontWeight: 300,
    fontSize: 25,
    [theme.breakpoints.down('md')]: {
      lineHeight: '24px',
      fontSize: 18,
    },
  };
  theme.typography['desc16'] = {
    ...defaultFontProps,
    // fontFamily: 'Poppins',
    lineHeight: '24px',
    fontWeight: 300,
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
      lineHeight: '20px',
      fontSize: 14,
    },
  };
  theme.typography['pagetitle'] = {
    ...defaultFontProps,
    // fontFamily: 'Poppins',
    lineHeight: '60px',
    fontWeight: 600,
    fontSize: 50,
    [theme.breakpoints.down('md')]: {
      lineHeight: '30px',
      fontSize: 30,
    },
  };
  theme.typography['programtitle'] = {
    ...defaultFontProps,
    // fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 30,
    [theme.breakpoints.down('md')]: {
      lineHeight: '30px',
      fontSize: 24,
    },
  };
  theme.typography['pagedesc'] = {
    ...defaultFontProps,
    fontSize: 19,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  };
  return responsiveFontSizes(theme);
};

export {getAppTheme};
