import {Fragment} from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import {ErrorBoundary} from 'mui-core';
import 'slick-carousel/slick/slick.css';
import {styled} from '@mui/material/styles';
import 'slick-carousel/slick/slick-theme.css';
import {Box, useTheme, useMediaQuery} from '@mui/material';
import {
  carouselLeftArrow,
  carouselRightArrow,
  carouselLeftArrowDark,
  carouselRightArrowDark,
} from 'assets/images';

const StyledCarouselContainer = styled(Box)(({count, fullWidth, theme}) => ({
  '& .slick-slider': {
    margin: '0px 32px',
    maxWidth: fullWidth ? 'initial' : 1300,
    [theme.breakpoints.only('sm')]: {
      margin: '12px',
    },
    [theme.breakpoints.only('xs')]: {
      margin: '0px',
    },
    '& .slick-list': {
      '& .slick-track': {
        margin: '0px',
      },
    },
    '& .slick-dots': {
      bottom: '0px',
      position: 'relative',
    },
    [`& a`]: {
      color: theme.palette.secondary.dark,
    },
    [`& .slick-dots button:before`]: {
      display: 'none',
    },

    [`& .slick-dots li`]: {
      width: 'initial',
      height: 8,
    },
    [`& .slick-dots button`]: {
      width: 32,
      height: 8,
      padding: 0,
      borderRadius: 5,
      border: `1px solid ${theme.palette.primary.main}`,
      [theme.breakpoints.down('sm')]: {
        width: 28,
      },
    },
    [`& .slick-dots li.slick-active button`]: {
      backgroundColor: theme.palette.primary.main,
    },
    '& .sliderBtn': {
      img: {
        width: '30px !important',
        height: '30px !important',
      },
      [theme.breakpoints.only('sm')]: {
        img: {
          width: '24px !important',
          height: '24px !important',
        },
      },
    },
  },
}));

const Carousel = ({
  fullWidth,
  content = [],
  slidesToScroll,
  showDots = true,
  slidesToShow = 4,
  showArrows = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.only('md'));

  let slidesToScrollLocal = 1;
  if (slidesToScroll) {
    slidesToScrollLocal = slidesToScroll;
  } else {
    if (isMobile) {
      slidesToScrollLocal = 1;
    } else if (isTablet) {
      slidesToScrollLocal = 2;
    } else if (isMedium) {
      slidesToScrollLocal = 3;
    } else {
      slidesToScrollLocal = slidesToShow;
    }
  }

  function PreviousBtn(props) {
    const {onClick, currentSlide, className, style} = props;
    const leftImgSrc =
      theme.palette.mode === 'dark' ? carouselLeftArrowDark : carouselLeftArrow;

    return (
      <>
        {currentSlide !== 0 && (
          <Box className='sliderBtn prevBtn' onClick={onClick}>
            <img
              className={className}
              src={leftImgSrc}
              style={style}
              alt='Prev'
            />
          </Box>
        )}
      </>
    );
  }

  function NextBtn(props) {
    const {onClick, slideCount, currentSlide, className, style} = props;
    const rightImgSrc =
      theme.palette.mode === 'dark'
        ? carouselRightArrowDark
        : carouselRightArrow;

    return (
      <>
        {currentSlide !== slideCount - slidesToShow && (
          <Box className='sliderBtn nextBtn' onClick={onClick}>
            <img
              className={className}
              src={rightImgSrc}
              style={style}
              alt='Next'
            />
          </Box>
        )}
      </>
    );
  }

  const settings = {
    lazyLoad: true,
    autoplay: false,
    infinite: isMobile,
    nextArrow: <NextBtn />,
    prevArrow: <PreviousBtn />,
    dots: showDots || isMobile,
    slidesToShow: slidesToShow,
    arrows: showArrows === false ? false : !isMobile,
    slidesToScroll: slidesToScrollLocal,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    content &&
    Array.isArray(content) &&
    content?.length > 0 && (
      <StyledCarouselContainer count={content.length} fullWidth={fullWidth}>
        <ErrorBoundary nameOfComponent='core-carousel'>
          <Slider
            {...settings}
            // afterChange={idx => console.log('change', idx)}
          >
            {content.map((slide, idx) => (
              <Fragment key={`slide-${idx}`}>{slide}</Fragment>
            ))}
          </Slider>
        </ErrorBoundary>
      </StyledCarouselContainer>
    )
  );
};

Carousel.propTypes = {
  fullWidth: PropTypes.bool,
  content: PropTypes.array.isRequired,
  slidesToShow: PropTypes.number || PropTypes.object,
};

export default Carousel;
