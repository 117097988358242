import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {licenseTypeKeys} from 'config';
import {isArray, isEmpty} from 'lodash';
import {Box, Stack, Typography, useTheme, useMediaQuery} from '@mui/material';
import {
  Button,
  PathCard,
  Carousel,
  EventCard,
  CompanyCard,
  EducatorCard,
  ErrorBoundary,
  RequestErrorLoader,
  BusinessPartnerCard,
} from 'mui-core';
import {
  BusinessRounded,
  FactoryOutlined,
  PinDropOutlined,
  BusinessCenterOutlined,
} from '@mui/icons-material';
import {
  ClockIcon,
  LocationIcon,
  ArrowCircleRight,
  industryIconBlack,
  industryIconWhite,
  verifiedBlueCheckmark,
} from 'assets/images';

const CardCarousel = ({
  dataset,
  heading,
  showDots,
  fullWidth,
  showArrows,
  description,
  redirectURL,
  fontType = {
    heading: {
      variant: 'h4',
    },
    description: {
      variant: 'body1',
    },
  },
  cardType = 'path',
  slidesToShow = 4,
}) => {
  const theme = useTheme();
  const {request, data: pathData = {}} = dataset || {};
  const {path_details = []} = pathData || {};
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const industryIcon =
    theme?.palette?.mode === 'light' ? industryIconBlack : industryIconWhite;

  const errorCompLabel = useMemo(() => {
    switch (heading) {
      case 'New Programs': {
        return 'home-CardCarousel-newest';
      }
      case 'Popular Programs': {
        return 'home-CardCarousel-popular';
      }
      case 'In-Demand Programs': {
        return 'home-CardCarousel-inDemand';
      }
      default: {
        return 'home-CardCarousel';
      }
    }
  }, [heading]);

  const renderContent = useMemo(() => {
    switch (cardType) {
      case 'path': {
        return path_details?.map(path => {
          const isCriminalJustice =
            path?.institute_details?.super_type_name ===
            licenseTypeKeys?.criminalJustice;
          return (
            <Box key={path?.uuid} sx={{padding: '8px 16px'}}>
              <PathCard
                enableNavigation
                data={path || {}}
                hideLocation={isCriminalJustice}
                hideAwardType={!path?.award_type_name}
              />
            </Box>
          );
        });
      }
      case 'educator': {
        return path_details?.map(path => (
          <Box key={path?.uuid} sx={{padding: '8px 16px'}}>
            <EducatorCard enableNavigation data={path || {}} />
          </Box>
        ));
      }
      case 'event': {
        return path_details?.map(path => (
          <Box key={path?.uuid} sx={{padding: '8px 16px'}}>
            <EventCard enableNavigation data={path || {}} />
          </Box>
        ));
      }
      case 'businessPartner': {
        return path_details?.map(path => (
          <Box key={path?.uuid} sx={{padding: '8px 16px'}}>
            <BusinessPartnerCard enableNavigation data={path || {}} />
          </Box>
        ));
      }
      case 'company': {
        return path_details?.map(path => (
          <Box key={path?.job_id} sx={{padding: '8px 16px'}}>
            <CompanyCard
              enableNavigation
              verifiedIcon={
                path?.verification === 'Approved' ? verifiedBlueCheckmark : null
              }
              data={{
                path: path.company_url,
                companyName: path?.name,
                companyLogo: path?.logo_cloudinary,
                companyBanner: path?.banner_cloudinary,
                industrySector: {
                  industryName: path?.industry_sector,
                  IndustrySectorIcon: (
                    <BusinessRounded
                      sx={{color: theme.palette.primary.light}}
                    />
                  ),
                },
                physicalAddress: {
                  address: path?.physical_address,
                  LocationIcon: PinDropOutlined,
                },
              }}
              infoItems={[
                {
                  label: 'Jobs',
                  count: path?.active_jobs || 0,
                  Icon: BusinessCenterOutlined,
                },
                {
                  Icon: FactoryOutlined,
                  label: 'Apprenticeships',
                  count: path?.active_apprenticeships || 0,
                },
              ].filter(Boolean)}
            />
          </Box>
        ));
      }
      case 'apprenticeships': {
        return path_details?.map(path => (
          <Box key={path?.job_id} sx={{padding: '8px 16px'}}>
            <CompanyCard
              centeredLogo={false}
              data={{
                path: path?.path,
                title: path?.job_title,
                companyName: path?.company,
                companyBanner: path?.banner,
                companyLogo: path?.company_logo,
                industrySector: {
                  industryName: path?.company_industry_sector,
                  IndustrySectorIcon: (
                    <img
                      width={18}
                      height={18}
                      src={industryIcon}
                      alt='industry icon'
                    />
                  ),
                },
              }}
              infoItems={[
                path?.program_length && {
                  imgIcon: (
                    <img
                      src={ClockIcon}
                      style={{
                        width: 14,
                        height: 14,
                        filter:
                          theme.palette.mode === 'dark' &&
                          theme.palette.iconLightColor.main,
                      }}
                    />
                  ),
                  value: path?.program_length,
                },
                path?.location && {
                  imgIcon: (
                    <img
                      src={LocationIcon}
                      style={{
                        width: 12,
                        height: 16,
                        marginBottom: '2px',
                        filter:
                          theme.palette.mode === 'dark' &&
                          theme.palette.iconLightColor.main,
                      }}
                    />
                  ),
                  value: path?.location,
                },
              ].filter(Boolean)}
              key={path?.job_id}
              target='_self'
              enableNavigation
            />
          </Box>
        ));
      }

      default: {
        return [];
      }
    }
  }, [cardType, path_details]);

  return (
    <ErrorBoundary nameOfComponent={errorCompLabel}>
      <RequestErrorLoader hideEmpty minHeight={100} body={dataset}>
        {!request &&
          path_details &&
          isArray(path_details) &&
          !isEmpty(path_details) && (
            <Stack spacing={4} my={4}>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent={heading ? 'space-between' : 'flex-end'}>
                {heading && description && (
                  <Box>
                    {heading && (
                      <Typography {...fontType?.heading} color='text.primary'>
                        {heading}
                      </Typography>
                    )}
                    {description && (
                      <Typography
                        mt={1.8}
                        color='text.primary'
                        {...fontType?.description}>
                        {description}
                      </Typography>
                    )}
                  </Box>
                )}
                {heading && !description && (
                  <Typography {...fontType?.heading} color='text.primary'>
                    {heading}
                  </Typography>
                )}
                <Link to={redirectURL}>
                  <Button
                    endIcon={
                      <img
                        src={ArrowCircleRight}
                        style={{filter: theme.palette.iconBrandColor.main}}
                      />
                    }
                    sx={{padding: isMobile ? '4px 24px' : '8px 24px'}}>
                    VIEW MORE
                  </Button>
                </Link>
              </Stack>
              <Carousel
                showDots={showDots}
                fullWidth={fullWidth}
                showArrows={showArrows}
                content={renderContent}
                slidesToShow={slidesToShow}
              />
            </Stack>
          )}
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

CardCarousel.propTypes = {
  heading: PropTypes.string,
  cardType: PropTypes.string,
  description: PropTypes.string,
  slidesToShow: PropTypes.number,
  dataset: PropTypes.object.isRequired,
  redirectURL: PropTypes.string.isRequired,
};

export default CardCarousel;
